const STATIC_PAGES = [
  'sign-in-email-sent',
  'confirmation-email-sent',
  'register-email-sent',
  'register-newsletter-subscribe-success',
  'register-newsletter-subscribe',
  'device-limit',
  'device-sign-in',
  'captcha',
  'newsletter-subscribe',
  'not-found',
  'log-(out|in)'
]

const STATIC_RESOURCE_PAGES = [
  'resources',
  'advertise',
  'about',
  'privacy',
  'terms',
  'cookie-policy',
  'contact-us',
  'FirstAnswers',
  'SEEAUKPrivacy',
  'CCPAPrivacyPolicy',
  'fwdealflow',
  'fwbiosimilarindex',
  'marketing-specs',
  'features-and-benefits',
  'whats-new'
]

const paths = [
  // Home Page
  {
    test: /^\/$/,
    track: ['page', 'view', 'home']
  },
  // Static Pages
  {
    test: new RegExp(`^\\/(${STATIC_PAGES.join('|')})`),
    track: params => ['page', 'view', params?.page]
  },
  // Resource Pages
  {
    test: new RegExp(`^\\/(${STATIC_RESOURCE_PAGES.join('|')})`),
    track: params => ['page', 'view', params?.page]
  },
  // Perspectives
  {
    test: /^\/perspectives\/(.+)/,
    track: params => ['page', 'view', params?.match?.slice(1)]
  },
  // My Account
  {
    test: /^\/my-account\/(.+)/,
    track: params => ['page', 'view', `my-account_${params?.page}`]
  },
  // Search
  {
    test: /^\/search\/(.+)/,
    track: params => ['page', 'view', `search_${params?.page}`]
  },
  // Ignored Paths
  { test: /^\/story\/\d+/, ignore: true }, //This one is tracked in the single story saga
  { test: /^\/sign-in/, ignore: true }, //This one is tracked in the gated landing page saga
  { test: /^\/register/, ignore: true }, //This one is tracked in the gated landing page saga
  { test: /^\/consent/, ignore: true }, //This one is tracked in the gated landing page saga
  { test: /^\/(river|stories)\/([^/]+)/, ignore: true }, //This one is tracked in the river saga
  { test: /^\/firstword-ai/, ignore: true }, //This one is tracked in the Chatsaga
  // Catch all
  {
    test: /.*/,
    track: params => ['page', 'view', params?.match?.slice(1)]
  }
]

export default path => {
  const match = paths.find(currentPath => currentPath.test.test(path))
  if (!match) throw new Error(`Piwik action for path ${path} not found.`)

  if (match.ignore) return null

  if (typeof match.track === 'function') {
    const matchedExpression = path.match(match.test)
    const params = {
      path,
      match: matchedExpression[0],
      page: matchedExpression[1]
    }
    return match.track(params)
  }

  return match.track
}
