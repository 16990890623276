import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  getSubscriptionTypes,
  getFreeSubscriptionTypes
} from '../../../../utils/oneCodeBase'
import { historyHandler } from '../../../../utils/index'
import { useHistory } from 'react-router-dom'
import { actions as newsletterActions } from '../../../MyNewsletters/reducer'
import usePiwik from '../../../../hooks/usePiwik'

const useOnboardingNewsletters = ({ configNewsletters, user }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [freeNewsletters, setFreeNewsletters] = useState(null)
  const [plusNewsletters, setPlusNewsletters] = useState(null)
  const [reportsNewsletters, setReportsNewsletters] = useState(null)
  const [emptyChecked, setEmptyChecked] = useState(false)

  const history = useHistory()
  const { handleTracking } = usePiwik()

  const subscribedNewsletters = useSelector(
    state => state.myNewsletters.subscribed
  )
  const allNewsletters = useSelector(
    state => state.myNewsletters.all_newsletters
  )

  const getFreeNewsletters = () => {
    const freeSubscriptions = getFreeSubscriptionTypes()
    const freeSiteSubscription = getSubscriptionTypes()?.FREE

    const filteredNewsletters = configNewsletters?.free?.filter(item =>
      freeSubscriptions.includes(item.subscription_type)
    )

    const freeNewsletters = filteredNewsletters?.map(item => {
      return {
        ...item,
        id: item.uuid,
        checked: item.subscription_type === freeSiteSubscription,
        disabled: false
      }
    })

    setFreeNewsletters(freeNewsletters ?? [])
  }

  const getPlusNewsletters = () => {
    const plusSiteSubscription = getSubscriptionTypes()?.PLUS

    const filteredNewsletters = configNewsletters?.plus?.filter(
      item => item.subscription_type === plusSiteSubscription
    )

    const plusNewsletters = filteredNewsletters?.map(item => {
      const hasPlusSubscription =
        user?.subscription_type === plusSiteSubscription

      return {
        ...item,
        id: item.uuid,
        checked: !!hasPlusSubscription,
        disabled: !hasPlusSubscription
      }
    })

    setPlusNewsletters(plusNewsletters ?? [])
  }

  const getReportsNewsletters = () => {
    const reportNewsletters = configNewsletters?.reports?.map(item => ({
      ...item,
      id: item.uuid,
      checked: true,
      disabled: false
    }))
    setReportsNewsletters(reportNewsletters ?? [])
  }

  const onToggleChange = data => {
    const updateNewsletterStatus = newsletters => {
      return newsletters.map(item => {
        return item.id === data?.id
          ? { ...item, checked: data.disabled ? false : data?.checked }
          : item
      })
    }

    let method = null

    switch (data?.type) {
      case 'free':
        method = setFreeNewsletters
        break
      case 'plus':
        method = setPlusNewsletters
        break
      case 'reports':
        method = setReportsNewsletters
        break
    }

    method(newsletters => updateNewsletterStatus(newsletters))
  }

  const submitNewsletters = () => {
    handleTracking('button', 'click', 'newsletter-onboarding_confirm')

    const newsletters = [
      ...freeNewsletters,
      ...plusNewsletters,
      ...reportsNewsletters
    ]

    // Check if empty
    if (newsletters.every(nl => nl.checked === false)) {
      return setEmptyChecked(true)
    }

    const mappedNewsletters = newsletters.map(item => {
      const mapped = allNewsletters.find(subitem => subitem.id === item.id)
      return { ...mapped, checked: item.checked }
    })

    const newslettersToSubscribe = mappedNewsletters.filter(item => {
      return (
        item.checked === true &&
        !item.disabled &&
        !subscribedNewsletters.find(subitem => subitem.id === item.id)
      )
    })

    const newslettersToUnsubscribe = mappedNewsletters.filter(item => {
      return (
        item.checked === false &&
        !item.disabled &&
        subscribedNewsletters.find(subitem => subitem.id === item.id)
      )
    })

    if (newslettersToSubscribe?.length > 0) {
      dispatch(
        newsletterActions.requestInsertNewsletters(newslettersToSubscribe)
      )
    }

    if (newslettersToUnsubscribe?.length > 0) {
      dispatch(
        newsletterActions.requestUnsubscribeNewsletters(
          newslettersToUnsubscribe
        )
      )
    }

    historyHandler(
      '/register-newsletter-subscribe-success',
      '',
      history,
      'replace'
    )
  }

  // Fetch user's newsletters
  useEffect(() => {
    dispatch(newsletterActions.requestListAllNewsletters())
    dispatch(newsletterActions.requestListSubscribedNewsletters())
  }, [])

  // Check that config newsletters exists in newsletters list
  useEffect(() => {
    getFreeNewsletters()
    getPlusNewsletters()
    getReportsNewsletters()
    setLoading(false)
  }, [configNewsletters?.plus?.length, configNewsletters?.free?.length])

  return {
    loading,
    freeNewsletters,
    plusNewsletters,
    reportsNewsletters,
    emptyChecked,
    submitNewsletters,
    onToggleChange
  }
}

export default useOnboardingNewsletters
