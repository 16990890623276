// React
import React from 'react'
import PropTypes from 'prop-types'

// Styling
import styles from './ComponentLoopItem.module.sass'
import cx from 'classnames'

// Components
import GeneralLoopItem from './children/GeneralLoopItem'
import SingleStoryLoopItem from './children/SingleStoryLoopItem'
import ContentViewLoopItem from './children/ContentViewLoopItem'

const ComponentLoopItem = props => {
  const item = props?.item || {}
  const className = item?.css?.length
    ? cx(item.css.map(style => styles[style]))
    : ''

  /*
    The `validContentViewOptions` array is defining a list of
    valid options for the ContentViewLoopItem component
  */
  const validContentViewOptions = [
    'lead_article',
    'view',
    'recommended_view',
    'dynamic_river',
    'my_reports_view',
    'recent_reports'
  ]

  /*
    The `validSingleStoryOptions` array is defining a list of
    valid options for the SingleStoryLoopItem component
  */
  const validSingleStoryOptions = [
    'single_story',
    'reference_article',
    'read_more_story_tags',
    'notify_me_updates_button',
    'report_related_updates'
  ]

  const childProps = {
    ...props,
    className,
    styles
  }

  // Render ContentViewLoopItem if a component matches the validContentViewOptions
  if (validContentViewOptions.includes(item?.component)) {
    return <ContentViewLoopItem {...childProps} />
  }

  // Render SingleStoryLoopItem if a component matches the validSingleStoryOptions
  if (validSingleStoryOptions.includes(item?.component)) {
    return <SingleStoryLoopItem {...childProps} />
  }

  // Render GeneralLoopItem as a default or if no components match the previous options
  return <GeneralLoopItem {...childProps} />
}

ComponentLoopItem.propTypes = {
  item: PropTypes.object
}

export default ComponentLoopItem
