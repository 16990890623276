import asyncMeta from '../AsyncFlags/metaCreator'
import { createReducer, createAction } from '@reduxjs/toolkit'

export const requestListReports = createAction(
  'ACCOUNT_LIST_REPORTS__REQUEST',
  null,
  asyncMeta
)
export const successListReports = createAction(
  'ACCOUNT_LIST_REPORTS__SUCCESS',
  null,
  asyncMeta
)
export const errorListReports = createAction(
  'ACCOUNT_LIST_REPORTS__ERROR',
  null,
  asyncMeta
)

export const requestReportsCount = createAction(
  'ACCOUNT_LIST_REPORTS_COUNT_REQUEST',
  null,
  asyncMeta
)
export const successReportsCount = createAction(
  'ACCOUNT_LIST_REPORTS_COUNT__SUCCESS',
  null,
  asyncMeta
)
export const errorReportsCount = createAction(
  'ACCOUNT_LIST_REPORTS_COUNT__ERROR',
  null,
  asyncMeta
)

export const requestListRecentReports = createAction(
  'RECENT_REPORTS_LIST__REQUEST',
  null,
  asyncMeta
)
export const successListRecentReports = createAction(
  'RECENT_REPORTS_LIST__SUCCESS',
  null,
  asyncMeta
)
export const errorListRecentReports = createAction(
  'RECENT_REPORTS_LIST_REPORTS__ERROR',
  null,
  asyncMeta
)

export const actions = {
  requestListReports,
  successListReports,
  errorListReports,
  requestReportsCount,
  successReportsCount,
  errorReportsCount,
  requestListRecentReports,
  successListRecentReports,
  errorListRecentReports
}

const initialState = {
  reports: {
    loading: null,
    error: null,
    data: null
  },
  count: {
    loading: true,
    error: null,
    data: null
  },
  recentReports: {
    loading: null,
    error: null,
    data: null
  }
}

const myReportsReducer = createReducer(initialState, {
  [requestListReports]: state => {
    state.reports = {
      loading: true,
      error: null,
      data: null
    }
  },
  [successListReports]: (state, { payload: reports }) => {
    state.reports = {
      loading: false,
      error: null,
      data: reports
    }
  },
  [errorListReports]: (state, { payload: error }) => {
    state.reports = {
      loading: false,
      error,
      data: null
    }
  },
  [requestReportsCount]: state => {
    state.count = {
      loading: true,
      error: null,
      data: null
    }
  },
  [successReportsCount]: (state, { payload: count }) => {
    state.count = {
      loading: false,
      error: null,
      data: count
    }
  },
  [errorReportsCount]: (state, { payload: error }) => {
    state.count = {
      loading: false,
      error,
      data: null
    }
  },
  [requestListRecentReports]: state => {
    state.recentReports = {
      loading: true,
      error: null,
      data: null
    }
  },
  [successListRecentReports]: (state, { payload }) => {
    const parsedData = {
      name: payload.name,
      data: payload.data.map(story => ({ ...story, isReport: true }))
    }
    state.recentReports = {
      loading: false,
      error: null,
      data: parsedData
    }
  },
  [errorListRecentReports]: (state, { payload: error }) => {
    state.recentReports = {
      loading: false,
      error,
      data: null
    }
  }
})

export default myReportsReducer
