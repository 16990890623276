import React from 'react'
import Page from '../../../../components/Page'
import List from '../../../../components/ui/List/List'
import ListItem from '../../../../components/ui/List/ListItem'
import Typography from '../../../../components/ui/Typography'
import styles from '../../../../modules/Static/Static.module.sass'
import SectionHeading from '../../../../components/SectionHeading'
import { Link } from 'react-router-dom'
import Metadata from '../../../../components/Metadata'

const TermsUsePage = () => {
  return (
    <Page className={styles.static}>
      <Metadata title="FirstWord Terms of Use" />
      <SectionHeading title="FirstWord Terms of Use" gutterBottom paragraph />
      <Typography type="body1" className={styles.paragraph} paragraph>
        These Terms of Use govern your use of the FirstWord Pharma newsletters
        and sites provided to you by FirstWord.
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        If you do not accept these Terms of Use, please do not use the FirstWord
        Pharma newsletters and sites. By submitting payment of the applicable
        fees, if any, and/or by using the FirstWord Pharma newsletters and
        sites, you indicate your acceptance to be bound by these Terms of Use.
      </Typography>
      <List className={styles.list} listStyle="number">
        <ListItem>Registration and Security</ListItem>
        <ListItem>Subscription Fees and Payment</ListItem>
        <ListItem>Changes to the Terms of Use</ListItem>
        <ListItem>Renewal</ListItem>
        <ListItem>Access and Use</ListItem>
        <ListItem>Restrictions on Use</ListItem>
        <ListItem>Changes to FirstWord Pharma</ListItem>
        <ListItem>
          Cancellation of the FirstWord Pharma Newsletters and Sites
        </ListItem>
        <ListItem>Third-party Web Sites, Links, and Information</ListItem>
        <ListItem>Third Party Advertisers</ListItem>
        <ListItem>
          Nature of the Service of Warranties and Limitations on Liability
        </ListItem>
        <ListItem>
          Disclaimers of Warranties and Limitations on Liability
        </ListItem>
        <ListItem>Subscriber Indemnification</ListItem>
        <ListItem>General</ListItem>
      </List>
      <Typography type="body1" className={styles.paragraph} paragraph>
        <strong>
          1. <u>Registration and Security.</u>
        </strong>{' '}
        You are responsible for providing accurate registration information and
        for updating it as necessary. Registration information provided by you
        will be used by FirstWord in accordance with our{' '}
        <Link className={styles.link} to="/privacy">
          Privacy Policy
        </Link>{' '}
        and may be processed in the United States or in any other country where
        FirstWord carries out its activities. By registering to the FirstWord
        Pharma newsletters and sites, you consent to the transfer of information
        outside of your country.
      </Typography>
      <div>
        <Typography type="body1" className={styles.paragraph} paragraph>
          <strong>
            2. <u>Subscription Fees and Payment.</u>
          </strong>
        </Typography>
        <List className={styles.list} listStyle="lower-alpha">
          <ListItem>
            You agree to pay the subscription fees and other charges incurred in
            connection with your subscription to and use of FirstWord Pharma
            (including any applicable taxes) at the rates in effect when the
            fees or charges were incurred. Please see{' '}
            <Link className={styles.link} to="/?paywall=benefits">
              Subscription Fees
            </Link>{' '}
            for a list of the current Subscription Fees. All charges will be
            billed to your credit card.
          </ListItem>
          <ListItem>
            If you are authorized to receive FirstWord Pharma through an
            arrangement with your employer, some or all of these "Subscription
            Fees and Payments" terms may not apply to you. Please contact your
            employer for details.
          </ListItem>
          <ListItem>
            Subscription fees are payable by credit card only. By submitting
            credit or card payment details to FirstWord, you warrant that you
            are entitled to purchase the FirstWord Pharma services using those
            payment details. In the case of unauthorized payments FirstWord
            reserves the right to suspend or terminate your access to the
            FirstWord Pharma newsletters and sites. If we do not receive payment
            authorization or if any authorization is subsequently cancelled, we
            may immediately terminate or suspend your subscription to the
            FirstWord Pharma newsletters and sites.
          </ListItem>
          <ListItem>
            30-Day Money-Back Guarantee. If at any time during the first 30 days
            of your subscription, you notify us by email at{' '}
            <a
              className={styles.link}
              href="mailto:customer.service@firstwordpharma.com"
            >
              customer.service@firstwordpharma.com
            </a>{' '}
            that you are not entirely satisfied with the FirstWord Pharma
            service and would like to cancel your subscription, we will refund
            your full subscription fee and cancel your subscription.
          </ListItem>
          <ListItem>
            Subscription Fees will be billed at the beginning of your
            subscription or any renewal. After the first 30-days of your
            subscription, all subscription fees and charges are nonrefundable.
            We may change the fees and charges for the next subscription period,
            by giving you advance notice.
          </ListItem>
        </List>
      </div>
      <Typography type="body1" className={styles.paragraph} paragraph>
        <strong>
          3. <u>Changes to the Terms of Use.</u>
        </strong>{' '}
        We may change the Terms of Use at any time. You will be notified when
        the revised Terms of Use are prominently displayed in the FirstWord
        Pharma newsletters or sites or when you are notified by any other means
        that would make a reasonable person aware of such changes. Your
        continued use of the FirstWord Pharma service after such notice
        constitutes your acceptance of the Terms of Use. These Terms of Use were
        published on November 11, 2004 and updated November 19, 2021.
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        <strong>
          4. <u>Renewal.</u>
        </strong>{' '}
        Your subscription shall renew automatically, unless we terminate it or
        you notify us of your decision to terminate your subscription. You must
        cancel your subscription before it renews in order to avoid billing of
        subscription fees for the renewal term to your credit card.
      </Typography>
      <div>
        <Typography type="body1" className={styles.paragraph} paragraph>
          <strong>
            5. <u>Access and Use.</u>
          </strong>
        </Typography>
        <List className={styles.list} listStyle="lower-alpha">
          <ListItem>
            The FirstWord Pharma service is offered in two different versions:
            (1) FirstWord Pharma; (2) FirstWord Pharma PLUS. Certain sections of
            the FirstWord Pharma newsletter are only accessible to users who pay
            to subscribe to FirstWord Pharma PLUS. Once you have completed the
            subscription process and your card has been authorized for payment,
            you will be given access to the appropriate FirstWord Pharma
            newsletters and sites sections.
          </ListItem>
          <ListItem>
            You are responsible for all use, activities, and fees associated
            with or arising from your use of the FirstWord Pharma service. You
            must promptly notify FirstWord of any unauthorized or unlawful use
            of the FirstWord Pharma service.
          </ListItem>
        </List>
      </div>
      <div>
        <Typography type="body1" className={styles.paragraph} paragraph>
          <strong>
            6. <u>Restrictions on Use.</u>
          </strong>
        </Typography>
        <List className={styles.list} listStyle="lower-alpha">
          <ListItem>
            Certain materials available via FirstWord Pharma, including without
            limitation FirstWord Pharma articles, are the property of FirstWord,
            its licensors or their respective owners as indicated. You may not
            remove or alter the copyright or trademark notice on any material on
            FirstWord Pharma.
          </ListItem>
          <ListItem>
            You acknowledge that FirstWord Pharma is a service mark of FirstWord
            and/or its licensors and you may not use it without written
            permission from FirstWord. All trademarks not owned by FirstWord are
            the property of their respective owners.
          </ListItem>
          <ListItem>
            You agree not to sell, publish, distribute, retransmit or otherwise
            provide access to the content received or accessible through the
            FirstWord Pharma services to anyone.
          </ListItem>
          <ListItem>
            We reserve the right to terminate or restrict your access to the
            FirstWord Pharma service if, in our opinion, your use of the service
            may violate any laws, infringe upon another person's rights or
            violate these Terms of Use.
          </ListItem>
        </List>
      </div>
      <Typography type="body1" className={styles.paragraph} paragraph>
        <strong>
          7. <u>Changes to FirstWord.</u>
        </strong>{' '}
        FirstWord reserves the right to make changes or modifications to
        FirstWord Pharma, including the manner of delivery and frequency with
        which FirstWord Pharma is provided to you. If you object to any material
        change in FirstWord Pharma, you may terminate your subscription by
        providing us with notice of your intent to do so and receive a pro rata
        refund for the unused portion of any prepaid term.
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        <strong>
          8. <u>Cancellation of the FirstWord Pharma Service.</u>
        </strong>{' '}
        FirstWord Publishing may stop the operation of the FirstWord Pharma
        service at any time without notice to you. If FirstWord cancels all or
        part of any of the FirstWord Pharma service, it shall not incur any
        liability to you, other than the pro rata refund for the unused portion
        of any prepaid term.
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        <strong>
          9. <u>Third-party Web Sites, Links, and Information.</u>
        </strong>{' '}
        The FirstWord Pharma newsletters and sites may contain information,
        products, and services provided by third parties and links to Internet
        web sites made available by third parties. These parties may have
        additional restrictions, terms, and conditions governing use of their
        content, for which you will be subject to. FirstWord does not control
        third-party web sites and FirstWord does not make any representations or
        warranties, express or implied, regarding information, products, or
        services associated with such web sites. The inclusion of any links to
        third-party web sites and Information in FirstWord Pharma does not
        constitute or imply an endorsement, authorization, sponsorship, or
        affiliation by or with FirstWord with respect to any third party, any
        third party’s web site or its content, or any information, products, or
        services provided by a third party.
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        <strong>
          10. <u>Third Party Advertisers.</u>
        </strong>{' '}
        The FirstWord Pharma newsletters and sites may contain the
        advertisements of third parties. Any correspondence or any other
        dealings with advertisers found on FirstWord Pharma are solely between
        you and such advertiser. You agree that FirstWord shall not be
        responsible for any loss or damage of any sort incurred as a result of
        any such dealings or as the result of the presence of such advertisers
        on FirstWord Pharma. Moreover, you shall not knowingly or intentionally
        interfere with the display of such advertisements.
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        <strong>
          11. <u>Nature of the Service.</u>
        </strong>{' '}
        FirstWord Pharma is an informational service for pharmaceutical
        professionals and does not constitute any form of medical or healthcare
        advice, recommendation or representation. No content provided through
        FirstWord Pharma is intended to be relied on for medical advice,
        diagnosis or treatment. Please contact a licensed physician, health care
        provider, or pharmaceutical manufacturer with questions involving a
        medical condition or treatment. Never disregard medical advice or delay
        in seeking it because of something you have received or accessed through
        FirstWord Pharma.
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        <strong>
          12. <u>Disclaimers of Warranties and Limitations on Liability.</u>
        </strong>{' '}
        FirstWord PROVIDES FIRSTWORD PHARMA SOLELY ON AN "AS IS BASIS," AND
        MAKES NO WARRANTIES, EXPRESS OR IMPLIED, CONCERNING FIRSTWORD AND
        EXPRESSLY DISCLAIMS ALL WARRANTIES, INCLUDING BUT NOT LIMITED TO
        WARRANTIES OF FITNESS FOR A PARTICULAR PURPOSE, WARRANTIES OF
        MERCHANTABILITY AND WARRANTIES OF TITLE, NON-INFRINGEMENT, AND ACCURACY.
        IN NO EVENT WILL FIRSTWORD OR ANY OF ITS AFFILIATES, AGENTS, LICENSORS,
        SUCCESSORS, ASSIGNS, OR LEGAL REPRESENTATIVES BE LIABLE FOR INDIRECT,
        SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES (INCLUDING, WITHOUT
        LIMITATION, DAMAGES FOR LOSS OF BUSINESS PROFITS, BUSINESS INTERRUPTION,
        LOSS OF BUSINESS INFORMATION OR OTHER PECUNIARY LOSS) ARISING DIRECTLY
        OR INDIRECTLY FROM THE USE OF (OR FAILURE TO USE) OR RELIANCE ON
        FIRSTWORD PHARMA, EVEN IF FIRSTWORD HAS BEEN ADVISED OF THE POSSIBILITY
        THAT SUCH DAMAGES MAY ARISE. FIRSTWORD DOES NOT GUARANTEE THE ACCURACY,
        CONTENT, OR TIMELINESS OF FIRSTWORD PHARMA AND FIRSTWORD SHALL NOT BE
        LIABLE OR RESPONSIBLE FOR ANY LOSS OR INJURY RESULTING DIRECTLY OR
        INDIRECTLY FROM THE USE OF, FAILURE TO PROVIDE, DELAY IN RECEIVING, OR
        RELIANCE ON DATA OR CONTENT CONTAINED THEREIN. In no event shall any
        liability of FirstWord, its affiliates, agents, licensors, successors,
        assigns, or legal representatives, if any, arising out of any kind of
        legal claim (whether in contract, tort, or otherwise) in any way
        connected with FirstWord Pharma exceed the amount the Subscriber paid to
        FirstWord in connection with FirstWord Pharma in the 12 month period
        immediately preceding the claim.
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        <strong>
          13. <u>Subscriber Indemnification.</u>
        </strong>{' '}
        You agree to indemnify, defend, and hold FirstWord, its affiliates, and
        their employees, officers, contractors, directors, shareholders, legal
        representatives, agents, successors, and assigns from and against any
        and all claims, liabilities, suits, demands, damages, losses, judgments,
        fines, penalties, interest, costs and expenses (including reasonable
        attorneys' fees and professional and court costs) directly, indirectly,
        wholly or partially arising from or relating to any breach of these
        Terms of Use by you, or any act or omission by you in activities arising
        from or relating to FirstWord Pharma.
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        <strong>
          14. <u>General.</u>
        </strong>{' '}
        These Terms of Use contain the final and entire agreement between us
        regarding your use of the FirstWord Pharma services and supersedes all
        previous and contemporaneous oral or written agreements regarding your
        use of the services. This Agreement is personal to you, which means that
        you may not assign your rights or obligations under this Agreement to
        anyone. Any attempted assignment in violation of this paragraph shall be
        void. These Terms of Use and the relationship between you and FirstWord
        shall be governed by and construed in accordance with the laws of the
        State of New York, as applied to agreements entered into and completely
        performed in New York. Any action to enforce this Agreement will be
        brought in the federal or state courts presiding in the State of New
        York, and all parties to this Agreement expressly agree to be subject to
        the jurisdiction of such courts. The United Nations Convention on
        Contracts for the International Sales of Goods shall not apply to this
        Agreement. If any provision of this Agreement is held unenforceable by a
        court of competent jurisdiction, that provision shall be enforced to the
        maximum extent permissible so as to effect the intent of the parties,
        and the remainder of this Agreement shall continue in full force and
        effect.
      </Typography>
    </Page>
  )
}

export default TermsUsePage
