import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../ui/Icon'
import { Link } from 'react-router-dom'
import styles from './ViewMoreLink.module.sass'
import { useSelector } from 'react-redux'
import { getConfig } from '../../modules/Config/selectors'

const ViewMoreLink = React.memo(({ children, to, className, ...props }) => {
  const internalToken = useSelector(getConfig('internal_token')) || null
  const { isExternal = false } = props
  const Component = isExternal ? 'a' : Link

  return (
    <Component
      href={isExternal ? `${to}?it=${internalToken}` : undefined}
      to={!isExternal ? to : undefined}
      className={styles.link + ` ${className || ''}`}
      rel={isExternal ? 'noopener noreferrer' : undefined}
      {...props}
    >
      <span className={styles.text}>{children}</span>
      <Icon
        icon="svg/material-design-icons/hardware/keyboard_arrow_right"
        colorTier="secondary"
        className={styles.icon}
      />
    </Component>
  )
})

ViewMoreLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
  isExternal: PropTypes.bool
}

export default ViewMoreLink
