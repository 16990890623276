// React
import React from 'react'
import PropTypes from 'prop-types'

// Components
import View from 'src/components/View'
import StoryExcerpt from 'src/components/StoryExcerpt'
import StoryRiverView from 'src/components/StoryRiverView'
import MyStoriesRiver from 'src/components/MyStoriesRiver'
import MyReportsView from 'src/components/MyReportsView'
import RecentReports from 'src/components/RecentReportsView'
import DynamicRiver from 'src/modules/DynamicRiver/DynamicRiverContainer'

/*
  ##################################
    BEGIN COMPONENTS DECLARATION
  ##################################
*/
export const LoopItem_lead_article = props => {
  const {
    viewsError,
    viewsLoading,
    view = null,
    styles = {},
    item = {}
  } = props
  return (
    <View
      error={viewsError}
      loading={viewsLoading}
      view={view}
      render={story => {
        return (
          <div className={styles.featuredStory} key={story.id}>
            <StoryExcerpt
              id={story.id}
              showBody={true}
              showImage={true}
              to={`/story/${story.id}`}
              title={story.title}
              body={story.teaser}
              congress={story.congress}
              published={story.published}
              image={story.image}
              reportSettings={story?.reportSettings}
              featured
              likes={story.likes}
              likedByProfile={story.likedByProfile}
              {...item}
            />
          </div>
        )
      }}
    />
  )
}

LoopItem_lead_article.propTypes = {
  viewsError: PropTypes.string,
  viewsLoading: PropTypes.bool,
  view: PropTypes.object,
  styles: PropTypes.object,
  item: PropTypes.object
}

export const LoopItem_view = props => {
  const {
    viewsError,
    viewsLoading,
    view = null,
    item = {},
    viewProps = {}
  } = props
  return (
    <StoryRiverView
      view={view}
      viewsError={viewsError}
      viewsLoading={viewsLoading}
      handleViewMoreClick={viewProps?.handleViewMoreClick}
      match={props.match}
      {...item}
    />
  )
}

LoopItem_view.propTypes = {
  viewsError: PropTypes.string,
  viewsLoading: PropTypes.bool,
  view: PropTypes.object,
  item: PropTypes.object,
  viewProps: PropTypes.object
}

export const LoopItem_recommended_view = props => {
  const {
    viewsError,
    viewsLoading,
    view = null,
    item = {},
    className = '',
    viewProps = {}
  } = props
  return (
    <MyStoriesRiver
      className={className}
      style={item?.customStyles}
      view={view}
      viewsError={viewsError}
      viewsLoading={viewsLoading}
      encodeArgs={viewProps?.encodeArgs}
      imageSize={viewProps?.mediaSize}
      getArticleLink={viewProps?.getArticleLink}
    />
  )
}

export const LoopItem_dynamic_river = props => {
  const { item = {}, className = '' } = props
  return (
    <DynamicRiver
      id={item?.name}
      interestsClickable={item.interestsClickable}
      showInterests={item.showInterests}
      config={item?.config}
      className={className}
      style={item?.customStyles}
    />
  )
}

LoopItem_dynamic_river.propTypes = {
  item: PropTypes.object,
  className: PropTypes.string
}

export const LoopItem_my_reports_view = props => {
  const { item = {}, className = '', viewProps = {} } = props

  return (
    <MyReportsView
      className={className}
      style={item?.customStyles}
      handleViewMoreClick={viewProps?.handleViewMoreClick}
      {...item}
    />
  )
}

LoopItem_my_reports_view.propTypes = {
  item: PropTypes.object,
  className: PropTypes.string,
  viewProps: PropTypes.object
}

export const LoopItem_recent_reports = props => {
  const { item = {}, className = '', viewProps = {} } = props

  return (
    <RecentReports
      className={className}
      style={item?.customStyles}
      handleViewMoreClick={viewProps?.handleViewMoreClick}
      {...item}
    />
  )
}

LoopItem_recent_reports.propTypes = {
  item: PropTypes.object,
  className: PropTypes.string,
  viewProps: PropTypes.object
}
