import React, { useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import PropTypes from 'prop-types'
import StoryRiverView from 'src/components/StoryRiverView'
import { actions } from 'src/modules/MyReports/reducer'

const RecentReportsView = props => {
  const dispatch = useDispatch()
  const {
    loading = null,
    data,
    error
  } = useSelector(state => state.myReports.recentReports, shallowEqual)

  useEffect(() => {
    dispatch(actions.requestListRecentReports({ size: props?.size }))
  }, [])

  if (data?.data && data?.data?.length > 0) {
    return (
      <StoryRiverView
        view={{ stories: data?.data }}
        viewsError={error}
        viewsLoading={loading}
        {...props}
      />
    )
  }
}

RecentReportsView.propTypes = {
  size: PropTypes.number
}

export default RecentReportsView
