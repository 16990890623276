import { useFlags } from 'launchdarkly-react-client-sdk'
import { useSelector } from 'react-redux'
import { useContext } from 'react'
import { SessionContext } from '../../modules/Session/context'
import { getConfig } from '../Config/selectors'

const useLaunchDarkly = () => {
  const flags = useFlags()
  const session = useContext(SessionContext)

  const featureFlagConfig = useSelector(getConfig('feature_flag_ldk')) || null

  const validateMenuItem = item => {
    if (item?.featureFlag) return showForFeatureFlag(item)
    if (item?.licenseType) return showForLicenseType(item)
    return item
  }

  const showForFeatureFlag = item => {
    if (flags[item?.featureFlag]) return item
    return item?.featureFlagFallback || null
  }

  const showForLicenseType = item => {
    const { checked: sessionReady, user } = session
    const { licenseType = null } = item
    if (
      sessionReady &&
      user?.license_types?.find(item => item?.name === licenseType)
    ) {
      return item
    }
    return item?.featureFlagFallback || null
  }

  const getFeatureFlagMenuItem = item => {
    if (item?.group) {
      let content = []
      for (const groupItem of item.content) {
        const validatedGroupItem = validateMenuItem(groupItem)
        if (validatedGroupItem) {
          content.push({ ...validatedGroupItem })
        }
      }
      item.content = content
      return item
    } else {
      return validateMenuItem(item)
    }
  }

  return {
    flags,
    featureFlagConfig,
    getFeatureFlagMenuItem,
    validateMenuItem
  }
}

export default useLaunchDarkly
