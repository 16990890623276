import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Grid,
  Button,
  Card,
  CardHeader,
  CardContent,
  Typography,
  styled
} from '@mui/material'
import styles from './upgradeUser.module.sass'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import Header from './header'
import Logo from 'src/components/Logo'
import usePiwik from 'src/hooks/usePiwik'
import usePayment from '../../Payment/usePayment'

const UpgradeUser = ({
  onClose,
  featureText,
  showRegistration,
  trackingKey,
  headerColor
}) => {
  const { handleTrackingWithArticleId } = usePiwik()
  const { priceWithCurrency = null } = usePayment()

  const redirect = () => {
    handleTrackingWithArticleId(
      'paywall',
      'button-click',
      `${trackingKey}_upgrade-now_pay-now`
    )
    window.location.assign('/checkout')
  }

  return (
    <div className={styles.modalBackdrop}>
      <Card className={styles.pharmaSubscriptionsContainer}>
        <CardHeader
          className={styles.cardHeader}
          component={() => (
            <Header
              onClose={onClose}
              featureText={featureText}
              headerColor={headerColor}
            />
          )}
        />
        <CardContent>
          <Box
            sx={{
              maxHeight: 'calc(100vh - 200px)',
              overflowY: 'auto',
              padding: '16px',
              '@media (max-width: 600px)': {
                maxHeight: 'calc(100vh - 250px)'
              }
            }}
          >
            <Grid container spacing={4}>
              {/* Primera columna */}
              <Grid item sm={1} />
              <Grid
                item
                sm={5}
                sx={{
                  width: '100% !important'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%'
                  }}
                >
                  <Box display="flex" justifyContent="center" width="100%">
                    <Logo className={styles.logo} width="220" height="19" />
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    width="100%"
                    marginBottom="22px"
                  >
                    <PaywallText
                      variant="subtitle1"
                      sx={{
                        fontWeight: 'bold'
                      }}
                    >
                      INDIVIDUAL ACCESS
                    </PaywallText>
                  </Box>

                  <Box>
                    <PaywallText
                      variant="body1"
                      className={styles.pharmaInsightsListTextItem}
                    >
                      <AddCircleIcon className={styles.addCircleIcon} />
                      Customise your service by monitoring specific interests of
                      importance to you, including products, companies,
                      conditions, medical meetings, regulatory areas, markets,
                      and more.
                    </PaywallText>
                    <PaywallText
                      variant="body1"
                      className={styles.pharmaInsightsListTextItem}
                    >
                      <AddCircleIcon className={styles.addCircleIcon} />
                      Unlimited access to timely, event-driven physician
                      intelligence on major news developments, issues, and
                      events.
                    </PaywallText>
                    <PaywallText
                      variant="body1"
                      className={styles.pharmaInsightsListTextItem}
                    >
                      <AddCircleIcon className={styles.addCircleIcon} />
                      Unlimited access to archives of 20+ years of searchable
                      content.
                    </PaywallText>
                  </Box>

                  <Box
                    sx={{
                      textAlign: 'center',
                      width: '100%',
                      marginTop: 'auto',
                      marginBottom: '10px'
                    }}
                  >
                    {priceWithCurrency && (
                      <PaywallText variant="body1">
                        {priceWithCurrency} / YEAR
                      </PaywallText>
                    )}
                  </Box>
                  <Button
                    className={styles.primaryButton}
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: '#ec8756ff',
                      color: 'white',
                      marginTop: 'auto',
                      textTransform: 'none'
                    }}
                    onClick={redirect}
                  >
                    Pay Now
                  </Button>
                </Box>
              </Grid>
              {/* Segunda columna */}
              <Grid item sm={5}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%'
                  }}
                >
                  <Box display="flex" justifyContent="center" width="100%">
                    <Logo width="220" height="19" className={styles.logo} />
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    width="100%"
                  >
                    <PaywallText
                      variant="subtitle1"
                      sx={{
                        textAlign: 'center',
                        fontWeight: 'bold'
                      }}
                    >
                      TEAM/COMPANY ACCESS
                    </PaywallText>
                    <PaywallText
                      className={styles.subtitle}
                      variant="body2"
                      sx={{
                        textAlign: 'center'
                      }}
                    >
                      All benefits of Individual Access PLUS:
                    </PaywallText>
                  </Box>
                  <Box>
                    <PaywallText
                      variant="body1"
                      className={styles.pharmaInsightsListItem}
                    >
                      <AddCircleIcon className={styles.addCircleIcon} />
                      Multiple user pricing discounts.
                    </PaywallText>
                    <PaywallText
                      variant="body1"
                      className={styles.pharmaInsightsListItem}
                    >
                      <AddCircleIcon className={styles.addCircleIcon} />
                      Personalised solutions, including Single Sign-On (SSO) and
                      RSS/XML/API news feeds.
                    </PaywallText>
                    <PaywallText
                      variant="body1"
                      className={styles.pharmaInsightsListItem}
                    >
                      <AddCircleIcon className={styles.addCircleIcon} />
                      Increase your ROI with a trusted, timely, comprehensive
                      global news and analysis service that meets each
                      individual's needs.
                    </PaywallText>
                    <PaywallText
                      variant="body1"
                      className={styles.pharmaInsightsListItem}
                    >
                      <AddCircleIcon className={styles.addCircleIcon} />
                      Save your colleagues time and empower them to react
                      quickly by adapting their monitored interests based on
                      their changing needs.
                    </PaywallText>
                  </Box>
                  <Button
                    className={styles.primaryButton}
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: '#ec8756ff',
                      color: 'white',
                      marginTop: 'auto',
                      textTransform: 'none'
                    }}
                    onClick={showRegistration}
                  >
                    Contact us
                  </Button>
                </Box>
              </Grid>

              <Grid item sm={1} />
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </div>
  )
}

UpgradeUser.propTypes = {
  onClose: PropTypes.func,
  featureText: PropTypes.string,
  showRegistration: PropTypes.func,
  trackingKey: PropTypes.string,
  headerColor: PropTypes.string
}

export default UpgradeUser

const PaywallText = styled(Typography, {
  name: 'Chat',
  label: 'chatWarning',
  overridesResolver: (props, styles) => [styles.warning]
})(() => ({
  color: '#7a7a7a'
}))
