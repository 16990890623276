import _set from 'lodash/set'
import dayjs from 'dayjs'
import { arrayify, extractDomainFromEmail } from '../../../utils'
import userflow from 'userflow.js'

const UserRepository = (
  Fw5MLApi,
  UserParser,
  SearchParser,
  NewsletterParser,
  DougallML
) => ({
  async auth(
    token,
    deviceId,
    captchaToken = null,
    metadata = null,
    loginToken = undefined
  ) {
    const body = {
      grant_type: 'authorization_code',
      token,
      cap: captchaToken,
      metadata,
      login_token: loginToken
    }
    _set(body, 'device.ip', null)
    /* New auth flow doesn't support device_id
    if (deviceId) {
      _set(body, 'device.device_id', `${deviceId}`)
    } else {
      _set(body, 'device.beginblackbox', blackbox || null)
    }
    */
    // Fix redirection type mismatch
    if (metadata && typeof metadata.redirection !== 'string') {
      _set(
        body,
        'metadata.redirection',
        metadata.redirection.pathname + metadata.redirection.search
      )
    }
    const req = await Fw5MLApi.fetch(`/auth`, 'POST', body)
    return req.data
  },

  async passwordlesslogin(email, metadata) {
    const body = {
      id: email,
      metadata
    }
    _set(body, 'device.ip', null)
    const req = await Fw5MLApi.fetch(`/profile/auth/email`, 'POST', body)
    return req.data
  },

  async passwordlessLoginAuth(token, captchaToken = null) {
    const body = {
      cap: captchaToken
    }
    _set(body, 'device.ip', null)
    const req = await Fw5MLApi.fetch(`/profile/auth`, 'POST', body, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return req.data
  },

  async registration(account) {
    const fwdpUser = await UserParser.buildFwdpUser(account)
    const req = await Fw5MLApi.fetch(`/registration`, 'POST', fwdpUser)
    return req.data
  },

  async confirmRegistration(token) {
    const body = {}
    _set(body, 'device.ip', null)
    const req = await Fw5MLApi.fetch(`/registration/confirm`, 'PUT', body, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return req.data
  },

  async updateAccount(id, profile) {
    if (profile.country === 'Unknown' || !profile.country) {
      _set(profile, 'country', null)
    }
    if (
      profile.job_function === 'default_job_function' ||
      !profile.job_function
    ) {
      _set(profile, 'job_function', null)
    }
    if (!profile.job_title) {
      _set(profile, 'job_title', null)
    }
    const req = await Fw5MLApi.fetch(`/profile/${id}`, 'PUT', profile)
    return req.data
  },

  async insertStatedInterest(interest) {
    const body = {
      [interest.category]: [interest.tag]
    }
    const req = await Fw5MLApi.fetch(`/profile/interests`, 'PUT', {
      interests: body
    })
    return req.data
  },

  async deleteStatedInterest(interest) {
    const encodedTag = encodeURI(interest.tag)
    const req = await Fw5MLApi.fetch(
      `/profile/interests/${interest.category}/${encodedTag}`,
      'DELETE'
    )
    return req.data
  },

  async listSavedSearches() {
    const req = await Fw5MLApi.fetch(`/profile/searches/list`)
    return SearchParser.parseSavedSearch(req.data)
  },

  async insertSavedSearch(name, search, alert) {
    const query = SearchParser.encodeSearchBody({ filters: search })
    const alert_frequency = alert === 'none' ? null : alert
    const req = await Fw5MLApi.fetch(`/profile/searches`, 'POST', {
      name,
      alert_frequency,
      search_request: query
    })
    return req.data
  },

  async updateSavedSearch(search_id, name, search, alert) {
    const { search_request } = search
    const alert_frequency = alert === 'none' ? null : alert
    const req = await Fw5MLApi.fetch(`/profile/searches`, 'PUT', {
      search_id,
      name,
      alert_frequency,
      search_request
    })
    return req.data
  },

  async deleteSavedSearch(id) {
    const req = await Fw5MLApi.fetch(`/profile/searches/${id}`, 'DELETE')
    return req.data
  },

  async getNewsletters() {
    const req = await Fw5MLApi.fetch(`/profile/newsletters`)
    const {
      data: { data = [] }
    } = req
    const nls = data.map(newsletter => NewsletterParser.mlToUi(newsletter))
    return NewsletterParser.filterTypes(nls)
  },

  async getNewsletterById(id) {
    const nls = await this.getNewsletters()
    return nls.find(newsletter => newsletter.id === id)
  },

  async insertNewsletter(id) {
    const ids = arrayify(id)
    const req = await Fw5MLApi.fetch(`/profile/newsletters`, 'PUT', {
      campaignIds: ids,
      from: 'site'
    })
    return req
  },

  async insertNewsletterOneClick(id, event_id, campaign_id, from_campaign_id) {
    const req = await Fw5MLApi.fetch(`/profile/newsletters`, 'PUT', {
      campaignIds: [id],
      tracking_info: {
        event_id,
        campaign_id,
        from_campaign_id
      }
    })
    return req
  },

  async deleteNewsletter(id) {
    const req = await Fw5MLApi.fetch(`/profile/newsletters/${id}`, 'DELETE')
    return req
  },

  async unsubscribeNewsletters(campaignIds) {
    return await Fw5MLApi.fetch(`/profile/newsletters/unsubscribe`, 'PUT', {
      campaignIds
    })
  },

  async getSettings() {
    const req = await Fw5MLApi.fetch(`/profile/settings`)
    return req
  },

  async saveSetting(settings) {
    const req = await Fw5MLApi.fetch(`/profile/settings`, 'PUT', {
      ...settings
    })
    return req
  },

  async getProfile() {
    const req = await Fw5MLApi.fetch(`/profile`)
    return req.data
  },

  async getPremiumAccountConsultant() {
    try {
      const req = await Fw5MLApi.fetch(`/profile/consultant`)
      return req.data
    } catch (e) {
      console.error(e.message)
      return null
    }
  },

  async updateDeliveryTime(
    newsletterUuid,
    deliveryTime,
    isRegionBased = false
  ) {
    const body = {
      newsletterUuid,
      deliveryTime,
      isRegionBased
    }
    const req = await Fw5MLApi.fetch(
      `/profile/newsletter/delivery`,
      'PUT',
      body
    )
    return req.data
  },

  async notifyMeSubscribe(newsletterUuid, articleId) {
    const body = {
      newsletterUuid,
      previewArticleIds: [articleId]
    }
    const req = await Fw5MLApi.fetch(`/profile/notify-me`, 'PUT', body)
    return req.data
  },

  async getRegionByCountry(country) {
    const body = {
      country
    }
    const req = await Fw5MLApi.fetch(
      `location/getRegionByCountry`,
      'POST',
      body
    )
    return req?.data
  },

  async initializeUserflow(user) {
    try {
      const token = process.env?.REACT_APP_USERFLOW_TOKEN || null

      // Avoid flow if there is no token
      if (!token || token === 'null') return

      userflow.init(token)

      const device_type = window.innerWidth <= 820 ? 'mobile' : 'desktop'

      // User flow
      if (user) {
        const currentDate = new Date()
        const iso8601Date = currentDate.toISOString()
        const companyType = user?.derived_company?.company_type
        const companyName = user?.derived_company?.company_name
        const subscriptions = user?.subscriptions ?? []
        const pharmaInfo = subscriptions.find(item => item.site_id === 1) ?? {}
        const healthtechInfo =
          subscriptions.find(item => item.site_id === 2) ?? {}

        let account_creation_date = null
        if (user?.created) {
          account_creation_date = dayjs.unix(1635445504).format('YYYY-MM-DD')
        }

        const customPayload = {
          name: `${user?.firstName} ${user?.lastName}`,
          email: user?.email,
          signed_up_at: iso8601Date,
          pharma_subscription: pharmaInfo?.license_type,
          pharma_premium_account_id: pharmaInfo?.premium_account_id,
          healthtech_subscription: healthtechInfo?.license_type,
          healthtech_premium_account_id: healthtechInfo?.premium_account_id,
          email_domain: extractDomainFromEmail(user?.email),
          account_creation_date,
          is_anon: false,
          device_type,
          companyName,
          companyType
        }

        userflow.identify(user?.profile_id, customPayload)
        return
      }

      // Anon flow
      userflow.identifyAnonymous({
        is_anon: true,
        device_type
      })
    } catch (e) {
      console.log(e)
      return
    }
  },

  async resetUserFlow() {
    const token = process.env?.REACT_APP_USERFLOW_TOKEN || null

    // Avoid flow if there is no token
    if (!token || token === 'null') return

    const device_type = window.innerWidth <= 820 ? 'mobile' : 'desktop'

    // Makes Userflow forget about the current user and re-identify the anon user.
    userflow.reset()
    userflow.identifyAnonymous({
      is_anon: true,
      device_type
    })
  }
})

export default UserRepository
