import { matchPath } from 'react-router-dom'
import { regexAlphanumericLowerSnakeCase } from '../../utils'

export const isValidIconSize = iconSize => {
  return Boolean(iconSize?.match(/^\d+px$/g))
}
export const getNumericIconSize = iconSize => {
  if (!isValidIconSize) return
  return Number(iconSize?.replace(/px/, ''))
}

export const isvalidMaxIconSize = iconSize => {
  const validatedIconSize = getNumericIconSize(iconSize)
  return validatedIconSize <= 60
}
export const getMaxIconSize = iconSize =>
  isvalidMaxIconSize(iconSize) ? iconSize : 60

export const getIconSizePX = (size, iconsSize = '', options = {}) => {
  if (iconsSize && isValidIconSize(iconsSize)) {
    if (isvalidMaxIconSize(iconsSize)) return iconsSize
    return '60px'
  }
  if (!iconsSize) {
    return options[size] ?? '50px'
  }
}

export const isIconShowing = (iconProps = {}) => {
  const iconValues = Object.keys(iconProps)
  return iconValues.length > 0 && iconProps?.visible === true
}

export const getPage = () => {
  const { pathname } = window.location

  // Story
  if (/^\/story\/\d+$/.test(pathname)) return 'article'

  // Search River
  const matchSearch = matchPath(pathname, {
    path: '/search/:searchTerm*'
  })
  if (matchSearch?.params) {
    return 'river_search'
  }

  // River Pages
  const riverMatch = matchPath(pathname, {
    path: '/(river)/:riverKey/:args*'
  })
  if (riverMatch?.params) {
    const riverKey = riverMatch.params.riverKey
    const args = decodeURIComponent(riverMatch.params.args)
    return regexAlphanumericLowerSnakeCase(
      `river_${riverKey}${args && args !== 'undefined' ? '_' + args : ''}`
    )
  }

  // Default
  return 'home'
}
