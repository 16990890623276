import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { tagWithCategory } from '../../utils/propTypes'
import { Link } from 'react-router-dom'
import LineClamp from 'react-dotdotdot'
import Typography from '../ui/Typography'
import Tag from '../Tag'
import FullImage from '../FullImage'
import SaveButton from '../SaveButton'
import Interests from '../Interests'
import { fromNow } from '../../utils'
import styles from './StoryExcerpt.module.sass'
import classNames from 'classnames/bind'
import {
  getConfigFilteredTags,
  getConfig
} from '../../modules/Config/selectors'
import { requestFWReportTracking } from '../../modules/SingleStory/reducer'
import { siteName } from '../../utils/oneCodeBase'
import ActionButtons from '../ActionButtons'

const cx = classNames.bind(styles)

const FWReport = React.memo(({ dispatch, id, children, url, ...rest }) => {
  return (
    <div
      onClick={() => {
        dispatch(requestFWReportTracking({ id, url }))
      }}
      {...rest}
    >
      {children}
    </div>
  )
})

FWReport.propTypes = {
  dispatch: PropTypes.func,
  id: PropTypes.string,
  children: PropTypes.node,
  url: PropTypes.string
}

const Article = ({ title, sidebar }) => (
  <Typography
    type="title"
    className={cx(styles.title, { sidebar })}
    gutterBottom
  >
    {title}
  </Typography>
)

Article.propTypes = {
  title: PropTypes.string,
  sidebar: PropTypes.any
}

const StoryExcerpt = ({
  congress,
  title: titleSource = 'Title',
  to = '/',
  id = '',
  published,
  image = null,
  body,
  type,
  plus,
  typeTo = '/',
  saveButton,
  featured,
  tags = [],
  source,
  sponsor,
  interestsClickable = true,
  showInterests = true,
  showActionBar = false,
  includeTags = true,
  isReversed = false,
  publishedPosition = 'bottom',
  actionButtonsConfig = {},
  featuredSettings = {},
  origin = [],
  showSource = true,
  showImage = false,
  showBody = false,
  sidebar = false,
  objectFit = 'cover',
  reportSettings,
  likes = 0,
  likedByProfile = false,
  showTitleOnly = false,
  isReport = false
}) => {
  const internalToken = useSelector(getConfig('internal_token')) || null
  const externalSitesConfig = useSelector(getConfig('external_sites')) || {
    Pharma: {},
    HealthTech: {},
    Reports: {}
  }

  const dispatch = useDispatch()
  const ref = useRef(null)
  const title = `${titleSource}${
    congress ? `: Presented at ${congress.join(', ')}` : ''
  }`

  const filteredTags =
    useSelector(
      getConfigFilteredTags({
        interests: tags
      })
    ) || tags

  const pharmaOriginValidation =
    origin.includes('FW4') || origin.includes('Pharma')

  const healthtechOriginValidation =
    origin.includes('MedDev') || origin.includes('HealthTech')

  const reportsOriginValidation = isReport

  const renderOriginTag = () => {
    // No valid tag found
    if (!pharmaOriginValidation && !healthtechOriginValidation) {
      return null
    }

    // Default tag name to current Site
    let tagName = siteName

    // External Sites tag
    if (!pharmaOriginValidation && healthtechOriginValidation) {
      tagName = 'HealthTech'
    } else if (!healthtechOriginValidation && pharmaOriginValidation) {
      tagName = 'Pharma'
    }

    return <Tag label={`FirstWord ${tagName}`} />
  }

  const buildOriginLink = () => {
    let externalUrl = ''
    let domainUrl = ''
    let isExternalLink = false

    const validateExternalLink = (originValidation, externalKey) => {
      if (!originValidation) {
        domainUrl = externalSitesConfig[externalKey]?.url
        isExternalLink = true
      }
    }

    switch (siteName) {
      case 'Pharma':
        if (healthtechOriginValidation)
          validateExternalLink(pharmaOriginValidation, 'HealthTech')
        if (reportsOriginValidation)
          validateExternalLink(pharmaOriginValidation, 'Reports')
        break
      case 'HealthTech':
        if (pharmaOriginValidation)
          validateExternalLink(healthtechOriginValidation, 'Pharma')
        if (reportsOriginValidation)
          validateExternalLink(healthtechOriginValidation, 'Reports')
        break
      case 'Reports':
        if (pharmaOriginValidation)
          validateExternalLink(reportsOriginValidation, 'Pharma')
        if (healthtechOriginValidation)
          validateExternalLink(reportsOriginValidation, 'HealthTech')
        break
      default:
        break
    }

    if (isExternalLink) {
      externalUrl = `${domainUrl}${to}`
      if (internalToken) {
        externalUrl += `?it=${internalToken}`
      }
    }

    return isExternalLink ? (
      <a
        href={externalUrl}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.link}
      >
        <Article title={title} sidebar={sidebar} />
      </a>
    ) : (
      <Link to={to} className={styles.link}>
        <Article title={title} sidebar={sidebar} />
      </Link>
    )
  }

  return (
    <article
      ref={ref}
      className={cx(
        featured ? styles.featured : styles.container,
        isReversed && styles.containerReverse,
        showTitleOnly && styles.simpleTitleContainer
      )}
      style={featuredSettings?.customStyles || {}}
    >
      {showImage && image && (
        <div
          className={cx(
            styles.imageContainer,
            objectFit === 'contain' ? '' : 'boxShadow'
          )}
        >
          <FullImage
            className={styles.image}
            src={image}
            objectFit={objectFit}
            width="100%"
            height="100%"
          />
        </div>
      )}
      <div className={styles.content}>
        {saveButton && (
          <div className={styles.saveButton}>
            <SaveButton />
          </div>
        )}
        {includeTags && type && <Tag to={typeTo} label={type} />}
        {includeTags && plus && <Tag className={styles.plus} label="PLUS" />}
        {includeTags && origin?.length > 0 && renderOriginTag()}
        <header>
          {isReport ? (
            <FWReport
              dispatch={dispatch}
              id={to.split('/')[2]}
              url={`${externalSitesConfig['Reports']?.url}${to}?it=${internalToken}`}
              className={styles.link}
            >
              <Article title={title} sidebar={sidebar} />
            </FWReport>
          ) : (
            buildOriginLink()
          )}
        </header>
        {publishedPosition === 'top' && published && (
          <div>
            <div className={cx(styles.published, styles.publishedTop)}>
              {fromNow(published)}
            </div>
          </div>
        )}
        {showBody && body && (
          // This will cut the paragraph and put 3 dots after like ...
          <LineClamp clamp={3} className={styles.body}>
            <Typography type="body1">{body}</Typography>
          </LineClamp>
        )}
        {showSource && source && (
          <p className={styles.source}>{source && `Ref: ${source}`}</p>
        )}
        {showInterests && !!filteredTags.length && (
          <Interests tags={filteredTags} clickable={interestsClickable} />
        )}
        {publishedPosition === 'bottom' && published && (
          <footer>
            <div className={styles.published}>{fromNow(published)}</div>
          </footer>
        )}
        {sponsor && (
          <footer>
            <div className={styles.sponsor}>{sponsor}</div>
          </footer>
        )}
        {showActionBar && (
          <div className={styles.iconsContainer}>
            <ActionButtons
              {...actionButtonsConfig}
              learnMoreURL={to}
              reportSettings={reportSettings}
              articleId={id}
              articleBody={body}
              articleTitle={title}
              likeProps={{
                likes,
                likedByProfile
              }}
              // sharedButtonsConfig={{ align: 'right', iconsExactSize: '30px' }}
            />
          </div>
        )}
      </div>
    </article>
  )
}

StoryExcerpt.propTypes = {
  /**
   * The excerpt id
   */
  id: PropTypes.string || PropTypes.number,
  /**
   * The excerpt title
   */
  title: PropTypes.string,
  /**
   * The excerpt link to full story
   */
  to: PropTypes.string,
  /**
   * The date published
   */
  published: PropTypes.number,
  /**
   * The excerpt image
   */
  image: PropTypes.string,
  /**
   * The excerpt body text
   */
  body: PropTypes.string,
  /**
   * The excerpt "type" tag
   */
  type: PropTypes.string,
  /**
   * If `true`, this is marked as a "plus" excerpt
   */
  plus: PropTypes.bool,
  /**
   * The link for the "type" tag
   */
  typeTo: PropTypes.string,
  /**
   * If `true`, this excerpt displays a "save" button
   */
  saveButton: PropTypes.bool,
  /**
   * The excerpt displays "featured" styling
   */
  featured: PropTypes.bool,
  /**
   * The array of tags to be displayed for this excerpt
   */
  tags: PropTypes.arrayOf(tagWithCategory),
  /**
   * The source to be displayed
   */
  source: PropTypes.string,
  /**
   * The sponsor to be displayed
   */
  sponsor: PropTypes.string,
  /**
   * Flag to include tags or not
   */
  includeTags: PropTypes.bool,
  /**
   * Positions to include tags or not
   */
  publishedPosition: PropTypes.oneOf(['normal', 'top', 'bottom']),
  /**
   * Flag to determinate report flow
   */
  isReversed: PropTypes.bool,
  /**
   * Flag to determinate the action bar visibility
   */
  showActionBar: PropTypes.bool,
  /**
   * Other props
   */
  congress: PropTypes.array,
  interestsClickable: PropTypes.bool,
  showInterests: PropTypes.bool,
  actionButtonsConfig: PropTypes.object,
  featuredSettings: PropTypes.object,
  origin: PropTypes.array,
  showSource: PropTypes.bool,
  showImage: PropTypes.bool,
  showBody: PropTypes.bool,
  sidebar: PropTypes.any,
  objectFit: PropTypes.string,
  reportSettings: PropTypes.object,
  likes: PropTypes.number,
  likedByProfile: PropTypes.bool,
  showTitleOnly: PropTypes.bool,
  isReport: PropTypes.bool
}

export default StoryExcerpt
